import { css, html, LitElement, PropertyValues } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { property } from "lit/decorators/property.js";
import { styleMap } from "lit/directives/style-map.js";
import { AsyncController } from "../../shared/controllers/async-controller";
import { DEFAULT_BASE_URL, SUPPORTED_LANGUAGES } from "../../api-sdk/constants";
import "../../shared/components/progress-bar";
import { _loading } from "../../shared/fragments/loading-template";
import { _errorMessage } from "../../shared/fragments/error-message-template";
import { defineCustomText, useCustomText } from "../../shared/fragments/custom-text";
import { cssReset } from "../../shared/css-reset";
import { _cssVariableMap } from "../../shared/fragments/css-variable-map";
import { enforceConfig } from "../../shared/enforce-config";
import { MissingConfig } from "../../shared/beam-errors";
import { TNumericId } from "../../shared/types";
import { attachLocalStorage } from "../../shared/local-storage";
import { getChainNonprofits, postSelectNonprofit } from "../../api-sdk/v3/routes";
import { LANGUAGES } from "../../api-sdk/types";
import { BeamNonprofitSelectEvent } from "../../shared/events";
import { getCookieValue } from "../../shared/cookies";
import { strings } from "./strings";

interface RequiredConfig {
  apiKey: string;
  chainId: TNumericId;
  storeId: TNumericId;
}

export class BeamSelectNonprofit extends LitElement {
  @property({ type: String }) public baseUrl: string = DEFAULT_BASE_URL;

  @property({ type: String }) public apiKey?: RequiredConfig["apiKey"];

  @property({ type: Number }) public chainId?: RequiredConfig["chainId"];

  @property({ type: Number }) public storeId?: RequiredConfig["storeId"];

  @property({ type: String }) public countryCode?: string;

  @property({ type: String }) public postalCode?: string;

  @property({ type: Number, reflect: true }) public selectedNonprofitId?: TNumericId;

  @property({ type: String }) public lang: LANGUAGES = "en";

  @property({ type: Boolean }) public debug = false;

  private selectionId?: string;

  get configLang() {
    return SUPPORTED_LANGUAGES[this.lang] || "en";
  }

  private getChainNonprofits = async () => {
    if (!enforceConfig<RequiredConfig>(["apiKey", "chainId"], this)) {
      throw new MissingConfig();
    }
    const res = await getChainNonprofits({
      baseUrl: this.baseUrl,
      headers: {
        authorization: `Api-Key ${this.apiKey}`,
      },
      pathParams: {
        chainId: this.chainId,
      },
      queryParams: {
        storeId: this.storeId,
        postalCode: this.postalCode,
        countryCode: this.countryCode,
        widgetName: "select-nonprofit",
        version: "1.0.0",
        lang: this.configLang,
      },
    });

    // Reset selection if list doesn't include the current selected nonprofit
    if (this.selectedNonprofitId && !res.nonprofits.map((np) => np.nonprofit.id).includes(this.selectedNonprofitId)) {
      this.selectedNonprofitId = undefined;
      this.dispatchEvent(
        new BeamNonprofitSelectEvent({ selectedNonprofitId: this.selectedNonprofitId, selectionId: this.selectionId })
      );
    }

    // this.storeId = res.storeId
    // TODO: if store ID was not provided, get the store ID from response here
    // TODO: add store ID to API response

    this.localStorage.setItem(
      "chainNonprofits",
      JSON.stringify({
        createdAt: new Date(),
        data: res,
      })
    );

    return res;
  };

  private postSelectNonprofit = async ({ selectedNonprofitId }: { selectedNonprofitId: TNumericId }) => {
    if (!enforceConfig<RequiredConfig>(["apiKey", "chainId", "storeId"], this)) {
      throw new MissingConfig();
    }
    const cartId = await getCookieValue("cart");
    const result = await postSelectNonprofit({
      baseUrl: this.baseUrl,
      headers: {
        authorization: `Api-Key ${this.apiKey}`,
      },
      requestBody: {
        nonprofitId: selectedNonprofitId,
        selectionId: this.selectionId,
        storeId: this.storeId,
        cartId,
      },
    });

    this.selectionId = result?.selectionId;
    this.localStorage.setItem("transaction", this.selectionId);
    this.localStorage.setItem("nonprofit", selectedNonprofitId);

    await this.updateComplete;

    this.dispatchEvent(new BeamNonprofitSelectEvent({ selectedNonprofitId, selectionId: this.selectionId }));
  };

  private nonprofitListDataController = new AsyncController<typeof this.getChainNonprofits>(
    this,
    this.getChainNonprofits
  );

  private selectionDataController = new AsyncController<typeof this.postSelectNonprofit>(
    this,
    this.postSelectNonprofit
  );

  private localStorage = attachLocalStorage(this as LitElement & RequiredConfig);

  willUpdate(previousPropertyValues: PropertyValues) {
    // Reload nonprofit list on change of any of these props:
    // Also fires on first load as props go from undefined => value
    const requireNewDataProps = ["chainId", "baseUrl", "storeId", "apiKey", "countryCode", "postalCode", "lang"];
    for (const prop of requireNewDataProps) {
      if (previousPropertyValues.has(prop)) {
        this.nonprofitListDataController.exec();
        break;
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.restoreStateFromCache();
  }

  private async restoreStateFromCache() {
    if (!enforceConfig<RequiredConfig>(["apiKey", "chainId", "storeId"], this)) throw new MissingConfig();
    try {
      // Restore previous selected nonprofit
      this.selectedNonprofitId = parseInt(this.localStorage.getItem("nonprofit") || "") || undefined;
      // Restore previous transaction/selection
      // TODO: add max TTL for this cache item of 20 days [WEB-100]
      this.selectionId = this.localStorage.getItem("transaction") || undefined;

      // Try to restore nonprofit list if we have data and it's not too old
      // list will continue to refresh async and replace this data when ready
      const { createdAt, data } = JSON.parse(this.localStorage.getItem("chainNonprofits") || "{}");
      const cacheTtl = 2 * 60 * 60 * 1000;
      if (new Date(createdAt).valueOf() + cacheTtl > new Date().valueOf()) {
        this.nonprofitListDataController.data = data;
        this.nonprofitListDataController.loading = false;
      }

      // Create a new selection ID for the session (e.g., if restoring nonprofit after a completed order)
      if (this.selectedNonprofitId && !this.selectionId) {
        await this.selectionDataController.exec({ selectedNonprofitId: this.selectedNonprofitId });
      }

      if (this.selectedNonprofitId) {
        this.dispatchEvent(
          new BeamNonprofitSelectEvent({ selectedNonprofitId: this.selectedNonprofitId, selectionId: this.selectionId })
        );
      }
    } catch (err) {
      // ignore cache retrieval error and continue to fetch data
    }
  }

  /**
   * Factory for selection event handler
   *
   * Nonprofit selector implements radio-button semantics:
   * * If nothing is selected, tabbing into selector selects first card
   * * Arrow keys changes focus between cards, but doesn't select
   * * Enter/Space sets selection
   * * If a nonprofit is selected, arrow keys change focus AND selection
   * * Click sets selection
   * @param {number} id
   * @param {number} index
   * @param {{id: number}[]} nonprofits
   * @returns {(evt: Event) => void}
   */
  private makeHandleSelect =
    (id: number, index: number, nonprofits: { nonprofit: { id: number } }[]) => async (evt: Event) => {
      const currentId = this.selectedNonprofitId;
      if (evt instanceof KeyboardEvent) {
        let nextFocus = null;
        switch (evt.key) {
          case "ArrowUp":
          case "ArrowLeft":
            if (index === 0) {
              nextFocus = nonprofits[nonprofits.length - 1];
            } else {
              nextFocus = nonprofits[index - 1];
            }
            evt.preventDefault();
            break;
          case "ArrowRight":
          case "ArrowDown":
            if (index === nonprofits.length - 1) {
              nextFocus = nonprofits[0];
            } else {
              nextFocus = nonprofits[index + 1];
            }
            evt.preventDefault();
            break;
          case "Enter":
          case " ":
            evt.preventDefault();
            break; // continue to toggle-selection block below
          default:
            return;
        }
        if (nextFocus) {
          if (currentId != null) {
            this.selectedNonprofitId = nextFocus.nonprofit.id;
          }
          const focusTarget = this.renderRoot.querySelector(`[data-value="${nextFocus.nonprofit.id}"]`) as HTMLElement;
          if (focusTarget !== null) {
            focusTarget.tabIndex = 0;
            focusTarget.focus();
          }
          return;
        }
      }
      // Handle selection with click or Enter/Space key
      const targetEl = evt.currentTarget;
      if (targetEl instanceof HTMLElement) {
        if (currentId === id) {
          // this["selected-nonprofit-id"] = undefined; // unset (not supported)
          return; // no API call or localStorage change needed
        } else {
          this.selectedNonprofitId = id;
        }
      }

      await this.selectionDataController.exec({ selectedNonprofitId: id });
    };

  public get cssVariables() {
    const defaults = {
      "--beam-fontFamily": "inherit",
      "--beam-fontSize": "inherit",
      "--beam-color": "inherit",
      "--beam-backgroundColor": "inherit",
      "--beam-ProgressBar-border-radius": "0px",
      "--beam-ProgressBar-height": "10px",
      "--beam-ProgressBar-color-background": "transparent",
      "--beam-ProgressBar-color-border": "currentColor",
      "--beam-ProgressBar-color-bar": "currentColor",
      "--beam-SelectNonprofit-maxWidth": "800px",
      "--beam-SelectNonprofit-options-iconHeight": "24px",
      "--beam-SelectNonprofit-options-padding": "10px",
      "--beam-SelectNonprofit-options-borderRadius": "0px",
      "--beam-SelectNonprofit-options-borderColor": "currentColor",
      "--beam-SelectNonprofit-options--selected-borderColor": "currentColor",
      "--beam-SelectNonprofit-options-backgroundColor": "transparent",
      "--beam-SelectNonprofit-options--selected-backgroundColor": "currentColor",
      "--beam-SelectNonprofit-details-borderRadius": "0px",
      "--beam-SelectNonprofit-details-borderColor": "currentColor",
      "--beam-SelectNonprofit-details-backgroundColor": "inherit",
      ...defineCustomText("--beam-SelectNonprofit-title", {
        fontSize: "1.25em",
        fontWeight: "bold",
      }),
      ...defineCustomText("--beam-SelectNonprofit-description", {
        marginTop: "0.5em",
      }),
      ...defineCustomText("--beam-SelectNonprofit-details-cause", {
        fontSize: "0.85em",
        fontWeight: "bold",
      }),
      ...defineCustomText("--beam-SelectNonprofit-details-beamAttribution", {
        fontSize: "0.85em",
      }),
      ...defineCustomText("--beam-SelectNonprofit-details-impactDescription", {
        fontSize: "1em",
        marginTop: "10px",
      }),
      "--beam-SelectNonprofit-details-nonprofitName-fontWeight": "bold",
      "--beam-SelectNonprofit-details-nonprofitName-fontStyle": "inherit",
      "--beam-SelectNonprofit-details-fundingProgress-marginTop": "10px",
      ...defineCustomText("--beam-SelectNonprofit-details-fundingProgressLabel", {
        fontSize: "0.85em",
      }),
    };

    const remoteConfig = this.nonprofitListDataController?.data?.config?.web?.theme || {};

    const config = { ...defaults, ...remoteConfig };

    const serializable = Object.create({
      toCSS() {
        return _cssVariableMap(this as Record<string, string>);
      },
    });

    return Object.assign(serializable, config);
  }

  static styles = [
    cssReset,
    css`
      :host {
        display: block;
        max-width: var(--beam-SelectNonprofit-maxWidth, 800px);
        font-family: var(--beam-fontFamily);
        font-size: var(--beam-fontSize);
        background-color: var(--beam-backgroundColor);
        color: var(--beam-color-text);
      }

      .details-impactDescription {
        ${useCustomText("--beam-SelectNonprofit-details-impactDescription")}
      }

      .details-impactDescription .nonprofitName {
        font-weight: var(--beam-SelectNonprofit-details-nonprofitName-fontWeight, bold);
        font-style: var(--beam-SelectNonprofit-details-nonprofitName-fontStyle, inherit);
      }
    `,
  ];

  protected render() {
    const { selectedNonprofitId } = this;
    const { data, loading } = this.nonprofitListDataController;

    if (loading && !data) {
      // TODO: better loading UI
      // TODO: cache data?
      return _loading(); // TODO: css theme first
    }
    if (this.nonprofitListDataController.error) {
      if (this.debug) {
        return _errorMessage({ error: this.nonprofitListDataController.error });
      }
      return "";
    }
    if (this.selectionDataController.error) {
      if (this.debug) {
        return _errorMessage({ error: this.selectionDataController.error });
      }
      // do not show error screen for interactive errors by default
    }
    const nonprofits = data?.nonprofits || [];
    const selectedNonprofit = nonprofits.find((np) => np.nonprofit.id === selectedNonprofitId) || null;
    return html`
      <style>
        :host {
          ${this.cssVariables.toCSS()}
        }
      </style>
      <h3
        class="title"
        part="title"
        id="beam-SelectNonprofit-title"
        style="${useCustomText("--beam-SelectNonprofit-title")}"
      >
        ${data?.config.web.title || strings[this.configLang].ctaTitle()}
      </h3>
      <p class="description" part="description" style="${useCustomText("--beam-SelectNonprofit-description")}">
        ${data?.config.web.description || strings[this.configLang].ctaMessage({})}
      </p>
      <div
        class="options"
        part="options"
        role="radiogroup"
        aria-labelledby="beam-SelectNonprofit-title"
        style="display: flex; gap: 1rem; margin: 10px 0 0 0;"
      >
        ${repeat(
          nonprofits,
          (i) => i.nonprofit.id,
          ({ nonprofit }, index) => {
            const isSelected = selectedNonprofitId === nonprofit.id;
            const isFocusable = isSelected || (selectedNonprofit == null && index === 0);
            return html`
              <div
                class="option"
                part="option"
                role="radio"
                tabindex="${isFocusable ? 0 : -1}"
                data-value=${nonprofit.id}
                aria-checked=${isSelected}
                @click=${this.makeHandleSelect(nonprofit.id, index, nonprofits)}
                @keydown=${this.makeHandleSelect(nonprofit.id, index, nonprofits)}
                aria-label="${nonprofit.cause}"
                style="${styleMap({
                  cursor: "pointer",
                  flex: "1",
                  textAlign: "center",
                  lineHeight: "1",
                  padding: "var(--beam-SelectNonprofit-options-padding, 10px)",
                  borderWidth: "var(--beam-SelectNonprofit-options-borderWidth, 1px)",
                  borderStyle: "solid",
                  borderRadius: "var(--beam-SelectNonprofit-options-borderRadius, 0)",
                  borderColor: isSelected
                    ? nonprofit.causeColor || "var(--beam-SelectNonprofit-options--selected-borderColor, currentColor)"
                    : "var(--beam-SelectNonprofit-options-borderColor, currentColor)",
                  backgroundColor: isSelected
                    ? nonprofit.causeColor ||
                      "var(--beam-SelectNonprofit-options--selected-backgroundColor, currentColor)"
                    : "var(--beam-SelectNonprofit-options-backgroundColor, transparent)",
                })}"
              >
                <img
                  src="${isSelected ? nonprofit.causeIconSelectedUrl : nonprofit.causeIconUrl}"
                  alt=""
                  role="presentation"
                  style="
                        height: var(--beam-SelectNonprofit-options-iconHeight, 24px);
                        user-select: none;
                    "
                />
              </div>
            `;
          }
        )}
      </div>
      ${selectedNonprofit != null
        ? html`
            <div
              class="details"
              part="details"
              style="
                  border: 1px solid var(--beam-SelectNonprofit-details-borderColor);
                  border-radius: var(--beam-SelectNonprofit-details-borderRadius);
                  background-color: var(--beam-SelectNonprofit-details-backgroundColor);
                  padding: 10px;
                  margin: 10px 0 0 0;
                 "
            >
              <div style="display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap-reverse">
                <span
                  class="details-cause"
                  style="flex: 0 1; white-space: nowrap; ${useCustomText("--beam-SelectNonprofit-details-cause")}"
                >
                  ${selectedNonprofit.nonprofit.cause}
                </span>
                <span
                  class="details-beamAttribution"
                  style="flex: 0 1; white-space: nowrap; ${useCustomText(
                    "--beam-SelectNonprofit-details-beamAttribution"
                  )}"
                >
                  ${strings[this.configLang].beamAttribution()}
                </span>
              </div>
              <p class="details-impactDescription">${unsafeHTML(selectedNonprofit.impact.description)}</p>
              <div
                style="display: flex; margin-top: var(--beam-SelectNonprofit-details-fundingProgress-marginTop); align-items: center;"
              >
                <beam-progress-bar
                  value="${selectedNonprofit.impact.goalProgressPercentage}"
                  style="flex: 1 0;"
                ></beam-progress-bar>
                <span
                  class="details-fundingProgressLabel"
                  style="${useCustomText(
                    "--beam-SelectNonprofit-details-fundingProgressLabel"
                  )} white-space: nowrap; text-align: right; flex: 0 1; margin-left: 1rem;"
                >
                  ${selectedNonprofit.impact.goalProgressText}
                </span>
              </div>
            </div>
          `
        : ""}
    `;
  }
}

customElements.get("beam-select-nonprofit") || customElements.define("beam-select-nonprofit", BeamSelectNonprofit);

declare global {
  interface HTMLElementTagNameMap {
    "beam-select-nonprofit": BeamSelectNonprofit;
  }
}
